<template>
	<div class="label-container">
		<h4 class="mb-3">{{ title }}</h4>
		<ProductLabelInfo class="mb-4" :type="type" />
		<template>
			<ProductEditLabelList
				:type="PRODUCT_LABEL_SUB_TYPES.zeroInstallment"
				:list="installmentList"
				:disabled="installmentList.length > 0"
				:is-valid="!$v.installmentList.$error"
				title="0% Installment label"
				description="Set default label for any product that available for 0% installment plan."
				@onAdd="openModal(PRODUCT_LABEL_TYPES.primaryBadge, PRODUCT_LABEL_SUB_TYPES.zeroInstallment)"
				@onDelete="handleDelete"
			/>
			<hr>
			<ProductEditLabelList
				:type="PRODUCT_LABEL_SUB_TYPES.freeGift"
				:list="freeGiftList"
				:disabled="freeGiftList.length > 0"
				:is-valid="!$v.freeGiftList.$error"
				class="mt-4"
				title="Free gift label"
				description="Set default label for any product that has free gift promotion."
				@onAdd="openModal(PRODUCT_LABEL_TYPES.primaryBadge, PRODUCT_LABEL_SUB_TYPES.freeGift)"
				@onDelete="handleDelete"
			/>
			<hr>
			<ProductEditLabelList
				:type="PRODUCT_LABEL_SUB_TYPES.newProduct"
				:list="newProductList"
				:disabled="newProductList.length > 0"
				:is-valid="!$v.newProductList.$error"
				class="mt-4"
				title="New arrival label"
				description="Set default label for any product that has been set as a new product."
				@onAdd="openModal(PRODUCT_LABEL_TYPES.primaryBadge, PRODUCT_LABEL_SUB_TYPES.newProduct)"
				@onDelete="handleDelete"
			/>
			<hr>
			<ProductEditLabelList
				:type="PRODUCT_LABEL_SUB_TYPES.sale"
				:list="saleList"
				:disabled="saleList.length > 0"
				:is-valid="!$v.saleList.$error"
				class="mt-4"
				title="Sale label"
				description="Set default label for any product that has sale."
				@onAdd="openModal(PRODUCT_LABEL_TYPES.primaryBadge, PRODUCT_LABEL_SUB_TYPES.sale)"
				@onDelete="handleDelete"
			/>
			<hr>
			<ProductEditLabelList
				:type="PRODUCT_LABEL_SUB_TYPES.collectInOneHourPrimary"
				:list="collectInOneHourPrimary"
				:disabled="collectInOneHourPrimary.length > 0"
				class="mt-4"
				title="Collect at store in 1 hour (Primary)"
				description="Set default label for any product that has been set to include in Collect at store in 1 hour."
				@onAdd="openModal(PRODUCT_LABEL_TYPES.primaryBadge, PRODUCT_LABEL_SUB_TYPES.collectInOneHourPrimary)"
				@onDelete="handleDelete"
			/>
			<hr>
			<ProductEditLabelList
				:type="PRODUCT_LABEL_SUB_TYPES.collectInOneHourSecondary"
				:list="collectInOneHourSecondary"
				:disabled="collectInOneHourSecondary.length > 0"
				class="mt-4"
				title="Collect at store in 1 hour (Secondary)"
				description="Set default label for any product that has been set to include in Collect at store in 1 hour."
				@onAdd="openModal(PRODUCT_LABEL_TYPES.secondary, PRODUCT_LABEL_SUB_TYPES.collectInOneHourSecondary)"
				@onDelete="handleDelete"
			/>
			<hr>
		</template>

		<ModalProductLabel
			ref="modal-label"
			@onSubmit="handleSubmitModal"
		/>
		<BaseActionPanelStickyFooter
			:disabled-confirm="isLoading"
			is-edit
			@onCancel="$router.go({ path: 'ProductLabelList', force: true })"
			@onConfirm="handleSubmit"
		/>
	</div>
</template>

<script>
import { mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import ProductLabelInfo from '@/components/ProductLabelInfo.vue';
import ModalProductLabel from '@/components/ModalProductLabel.vue';
import ProductEditLabelList from '@/components/ProductEditLabelList.vue';
import {
	PRODUCT_LABEL_TYPES,
	PRODUCT_LABEL_SUB_TYPES,
} from '../enums/productLabels';

export default {
	name: 'ProductLabelListDefault',

	validations() {
		return {
			installmentList: {
				required,
			},
			freeGiftList: {
				required,
			},
			newProductList: {
				required,
			},
			saleList: {
				required,
			},
		};
	},
	components: {
		ProductLabelInfo,
		ModalProductLabel,
		ProductEditLabelList,
	},
	props: {
		value: {
			type: Array,
			default: () => [],
		},
		type: {
			type: String,
			default: PRODUCT_LABEL_TYPES.primary,
		},
		title: {
			type: String,
			default: 'Default label',
		},
	},
	data() {
		return {
			isLoading: false,
			PRODUCT_LABEL_SUB_TYPES,
			PRODUCT_LABEL_TYPES,
			deletedId: null,
			updateDeleteLabelsToNormalType: [],
		};
	},
	computed: {
		allLabels: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
		installmentList() {
			return this.allLabels.filter((item) => item.subType === PRODUCT_LABEL_SUB_TYPES.zeroInstallment);
		},
		freeGiftList() {
			return this.allLabels.filter((item) => item.subType === PRODUCT_LABEL_SUB_TYPES.freeGift);
		},
		newProductList() {
			return this.allLabels.filter((item) => item.subType === PRODUCT_LABEL_SUB_TYPES.newProduct);
		},
		saleList() {
			return this.allLabels.filter((item) => item.subType === PRODUCT_LABEL_SUB_TYPES.sale);
		},
		collectInOneHourPrimary() {
			return this.allLabels.filter((item) => item.subType === PRODUCT_LABEL_SUB_TYPES.collectInOneHourPrimary);
		},
		collectInOneHourSecondary() {
			return this.allLabels.filter((item) => item.subType === PRODUCT_LABEL_SUB_TYPES.collectInOneHourSecondary);
		},
	},
	methods: {
		...mapActions({
			updateProductLabelDefault: 'productLabels/postProductLabelDefault',
		}),
		handleSubmitModal({ labels, subType }) {
			this.allLabels = [
				...this.allLabels.filter((item) => {
					return item.subType !== subType && item.id !== labels[0].id;
				}),
				{ ...labels[0], subType },
			];
		},
		async handleSubmit() {
			this.$v.$touch();

			if (this.$v.$invalid) {
				return;
			}

			// filter update only subtype !== normal (prevent update a lot of labels then API broken)
			const defaultLabelIds = this.allLabels.filter((label) => label.subType !== PRODUCT_LABEL_SUB_TYPES.normal).map((label) => {
				return {
					id: label.id,
					sub_type: label.subType,
				};
			});

			// merge array defaultLabels and previous default label to update subtype to normal
			const mergeUpdateDefaultLabelsAndDeleteLabels = [...defaultLabelIds, ...this.updateDeleteLabelsToNormalType];
			this.isLoading = true;

			try {
				await this.updateProductLabelDefault(mergeUpdateDefaultLabelsAndDeleteLabels);
			} finally {
				this.isLoading = false;
			}
		},
		handleDelete({ id }) {
			this.allLabels = this.allLabels.map((item) => {
				if (item.id === id) {
					return {
						...item,
						subType: PRODUCT_LABEL_SUB_TYPES.normal,
					};
				}
				return item;
			});

			// keep previous default label to update subtype to normal
			const deleteData = this.allLabels.find((item) => item.id === id);

			if (deleteData) {
				this.updateDeleteLabelsToNormalType.push({ id: deleteData.id, sub_type: PRODUCT_LABEL_SUB_TYPES.normal });
			}
		},
		openModal(type, modalType) {
			this.$refs['modal-label'].open(type, modalType);
		},
	},
};
</script>

<style lang="scss" scoped>
	.label-container {
		margin-top: rem(40);
	}
</style>
