<template>
	<div class="label-container">
		<div class="d-flex align-items-center justify-content-between mb-3">
			<h4 class="mb-0">{{ title }}</h4>
			<div>
				<router-link
					:to="{
						name: 'ProductLabelBulkAttach',
						query: { type },
					}"
					class="btn btn-tertiary btn-bulk-attach"
				>
					Bulk attach label
				</router-link>

				<router-link
					:to="{
						name: 'ProductLabelCreate',
						query: { type },
					}"
					class="btn btn-secondary btn-create-label ml-3"
					data-test-id="create-product-labels"
				>
					<CIcon class="mr-2" name="cil-plus" /> Create label
				</router-link>
			</div>
		</div>
		<ProductLabelInfo :type="type" class="mb-3" />
		<p class="typo-body-2 color-black-45">
			Drag to order the label display sequence. On the website, The label will display followed by the label order below.
		</p>
		<BaseLoading v-if="isLoading" />
		<div
			v-else
			class="label-list"
			:class="emptyClasses"
		>
			<draggable
				v-if="value.length"
				:value="value"
				tag="ul"
				ghost-class="is-placeholder-item"
				class="label-list-dragable"
				@input="handleLabelOrder"
			>
				<ProductLabelListItem
					v-for="(label, index) in value"
					:key="label.id"
					:data="label"
					:type="type"
					class="label-list-item"
					@onMoveToTop="moveLabelToTop(index)"
				/>
			</draggable>
			<template v-else>
				<span class="subtitle color-black-45">No {{ type }} label in the list</span>
			</template>
		</div>
		<BaseActionPanelStickyFooter
			:disabled-confirm="isLoading"
			is-edit
			data-test-id="save-change-product-labels"
			@onCancel="$router.go({ path: 'ProductLabelList', force: true })"
			@onConfirm="handleSubmit"
		/>
	</div>
</template>

<script>
import { mapActions } from 'vuex';
import draggable from 'vuedraggable';
import ProductLabelListItem from '@/components/ProductLabelListItem.vue';
import ProductLabelInfo from '@/components/ProductLabelInfo.vue';
import { PRODUCT_LABEL_TYPES } from '../enums/productLabels';

export default {
	name: 'ProductLabelList',

	components: {
		draggable,
		ProductLabelListItem,
		ProductLabelInfo,
	},
	props: {
		value: {
			type: Array,
			default: () => [],
		},
		type: {
			type: String,
			default: PRODUCT_LABEL_TYPES.primary,
		},
		title: {
			type: String,
			default: 'Primary label',
		},
	},
	data() {
		return {
			isLoading: false,
		};
	},
	computed: {
		emptyClasses() {
			return !this.value.length ?
				'd-flex align-items-center justify-content-center'
				: '';
		},
	},
	methods: {
		...mapActions({
			sortProductLabels: 'productLabels/sortProductLabels',
		}),
		moveLabelToTop(index) {
			this.value.unshift(this.value.splice(index, 1)[0]);
		},
		handleLabelOrder(sortedList = []) {
			this.$emit('input', sortedList);
		},
		async handleSubmit() {
			this.isLoading = true;

			const labelIds = this.value.map((label) => label.id);

			try {
				await this.sortProductLabels({
					type: this.type,
					label_ids: labelIds,
				});
			} finally {
				this.isLoading = false;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
	.label-container {
		margin-top: rem(40);
	}

	.label-list {
		border: 2px solid $color-gray-100;
		background-color: $color-gray-100;
		border-radius: 4px;
		list-style: none;
		min-height: rem(272);

		// .label-list-dragable
		&-dragable {
			margin-bottom: 0;
			padding-left: 0;
		}
	}

	::v-deep .label-list-item:last-child {
		margin-bottom: 0;
	}
</style>
